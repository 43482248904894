/* eslint-disable  */
body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
}
a {
  text-decoration: none;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(75, 64, 58, 0.4);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

/* :root {
  scrollbar-color: rgb(210, 210, 210) rgb(46, 54, 69) !important;
  scrollbar-width: thin !important;
} */
