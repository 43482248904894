/* eslint-disable  */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable  */

.logoBox {
  display: block;
  text-align: center;
  z-index: 9;
  background-color: black;
  padding: 8px;
}
.logo {
  width: 100px;
  height: 50px;
}
